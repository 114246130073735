<template>
    <div>
        
        <div class="container">
            <div class="row">
                <div class="col-6">
                    <h5>{{title1}}</h5>
                </div>
                <div class="col-6">
                    <h5>{{title2}}</h5>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <img :src="logo" v-show="logoExist">
                    <p v-show="!logoExist">Your {{title1}} goes here.</p>
                </div>

                <div class="col-6">
                    <img :src="logo" v-show="logoExist">
                    <p v-show="!logoExist">Your {{title2}} goes here.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <div class="custom-file">
                        <input type="file" class="custom-file-input" ref="filePath" id="filePath" v-on:change="logoPath()">
                        <label class="custom-file-label" for="customFile">{{logo}}</label>
                    </div>
                </div>

                <div class="col-1">
                        <img id="download" src="../../../../assets/download.png" width="35px"/>
                </div>

                <div class="col-1">
                        <img id="download" src="../../../../assets/delete.png" width="35px"/>
                </div>

                <div class="col-4">
                    <div class="custom-file">
                        <input type="file" class="custom-file-input" ref="filePath" id="filePath" v-on:change="logoPath()">
                        <label class="custom-file-label" for="customFile">{{logo}}</label>
                    </div>
                </div>

                <div class="col-1">
                        <img id="download" src="../../../../assets/download.png" width="35px"/>
                </div>

                <div class="col-1">
                        <img id="download" src="../../../../assets/delete.png" width="35px"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data(){
        return{
            logoCharge:false,
            logoExist:false,
            logo:"Path to your logo file"
        }
    },
    methods:{
        logoPath(){
            this.logo = this.$refs.filePath.files[0].name;
        }
    },
    props:{
        Name1:String,
        title1:String,
        Name2:String,
        title2:String,
        token:String,
        Orga:String
    }
}
</script>
<style lang="css" scoped>

</style>