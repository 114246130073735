// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'babel-polyfill'
import app from './app'
import BootstrapVue from "bootstrap-vue"
import linkify from 'vue-linkify'
import router from './router'
import vSelect from 'vue-select'
import Vue from 'vue'
import { createPinia, PiniaVuePlugin } from 'pinia';
import VueLazyLoadVideo from 'vue-lazyload-video'
import GoogleTranslateSelect from '@google-translate-select/vue2'
export const EventBus = new Vue();

Vue.use(PiniaVuePlugin);
Vue.use(BootstrapVue)
//LazyVideo & LazyVideoAsGIF components used in PW
Vue.use(VueLazyLoadVideo)
Vue.use(GoogleTranslateSelect)
Vue.config.productionTip = false
//Automatic clickable links
Vue.directive('linkified', linkify)

Vue.component('v-select', vSelect)

//UI Logos and texts visualizations for differents domains
Vue.prototype.$setBrandSettings = function(){
  var domain = Vue.prototype.$brandSettings.domain;
  //siemens brand
  if(domain == "task2fit.helpmee.siemens.cloud" || domain == "arexperts.helpmee.siemens.cloud"){
    Vue.prototype.$brandSettings.logoImage = require("../../assets/task2fit.png");
    Vue.prototype.$brandSettings.brand = "Task2Fit";
    Vue.prototype.$brandSettings.appName = "Task2Fit";
  }
}
Vue.prototype.$brandSettings = {
  domain: self.location.host,
  logoImage: require("../../assets/GiriLogo.png"),
  brand: "GIRI",
  appName: "GIRI"
}
Vue.prototype.$setBrandSettings();

Vue.prototype.$backport = require("./../../../backend/server/config.json").port;
const domain=self.location.host;
if(domain =="localhost" || domain=="giri" || domain=="127.0.0.1")
  Vue.prototype.$address = "https://localhost:"+ Vue.prototype.$backport +"/";
else
  Vue.prototype.$address="https://"+self.location.host+"/";

/* eslint-disable no-new */
const pinia = createPinia();
new Vue({
  el: '#app',
  pinia,
  router,
  render: h => h(app),
  template: '<app/>',
  components: {app}
})

