<template>
    <div>
        <b-navbar toggleable="lg" type="light" variant="light">
            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav>
                    <b-nav-item v-on:click="emitCustomMenuChange('workplaces')">
                        Assign Projects
                    </b-nav-item>
                    <b-nav-item v-on:click="emitCustomMenuChange('workflows')">
                        Assign instructions
                    </b-nav-item>
                    <b-nav-item v-on:click="emitCustomMenuChange('workflows')">
                        Assign instructions
                    </b-nav-item>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>
</template>

<script>
    export default {
        methods:{
            emitCMMenuChange(nextTab){
                this.$emit("ChangeCMMenu", nextTab);
            }
        },
        mounted(){
            //{value:String,label:string}
            console.log(this.menVals);
        }
    }
</script>
<style lang="css" scoped>

</style>