<template>
<div>
    <stack-modal :show="onDoneUpload" @save="sendResponse(true)" @close="sendResponse(false)" :saveButton="saveButton" :cancelButton="cancelButton">
        <h2 class="text-secondary" style="text-align:center">Warning</h2>
        <br>
        <p>
            Deleting {{elementType}}  completely removes them from the database.
        </p>
        <p>
            If {{name}} has already been used in a instruction, this will no longer be displayed there.
        </p>
    </stack-modal>
</div>
</template>
<script>
import StackModal from '@innologica/vue-stackable-modal'
export default({
    components: {
        StackModal
    },
    props:{
        id:String,
        name:String,
        elementType:String
    },
    data(){
        return{
            cancelButton:{
                title: "Don't delete",
                visible: true,
                btnClass: {'btn btn-primary ': true}
            },
            saveButton:{
                title: 'Delete anyway',
                visible: true,
                btnClass: {'btn btn-light ': true}
                
            },
            onDoneUpload:true
        }
    },
    methods:{
        sendResponse(del){
            console.log(this.id);
            this.$emit("boolDelete", del, this.id);
        }
    },
    mounted() {
    },

})
</script>
<style lang="css" scoped>

</style>