<template>
        <div>            
            <div id="NewPassInt" v-show="this.visib">
                <h3 align="center">Write your new password</h3><br>
                <input type="password" class="form-control" id="newpassword" placeholder="New Password" v-model="pswrd"><br>
                <b-button v-on:click="resetPassword()" class="btn btn-primary">Confirm</b-button >
            </div>
            <div id="Message" v-show="!this.visib">
                <h2>{{this.statusMassage}}</h2>
            </div>
        </div>
</template>

<script>
    //import StackModal from '@innologica/vue-stackable-modal'
    import axios from 'axios'
    //import Vue from 'vue'
    export default {
        name:"ResetPassword",
        data(){
            return{
                token : "",
                newPass : "",
                address: "",
                pswrd: "",
                visib: true,
                statusMassage: "Oops, this token has expired."
            }
        },
        created(){
            this.token= this.$route.query.rectoken
            console.log("token " + this.token);
        },
        mounted(){
        },
            methods:{
                resetPassword(id){
                    this.visib = false;
                    const data={
                        newPassword:this.pswrd,
                    }
                    axios.post(this.$address+'api/UserAccounts/reset-password?access_token='+this.token, data).then(response=>{
                        this.statusMassage = "Your new password is set";
                    });
            }
        }

    }
</script>
<style lang="css" scoped>

</style>