<template>
    <div>
        <div class="d-flex flex-row-reverse" v-if="usertype==='admin'">
            <button type="button" v-if="usertype==='admin' || usertype==='manager' " class="btn btn-secondary" @click="showDownloadModal=true" v-on:click="DownloadLogFile" id="DownloadLogBtn" >Download Log File</button>

            <stack-modal :show="showDownloadModal" title="Select log to download" :saveButton=saveButtonDefaults :cancelButton=cancelButtonDefaults @close="showDownloadModal=false">
            <table id="tablelogs" class="table">
                <thead>
                    <tr>
                        <th scope="col" style="width: 35%">Name</th>
                        <th scope="col" style="width: 35%">Last Edit Date</th>
                        <th scope="col" style="width: 35%">Download</th>
                    </tr>
                </thead>
                <tbody>        
                        <tr class="logs" v-for="log in logfiles" :key="log.name">
                            <th scope="col" style="width: 35%"> {{log.name}}  </th>
                            <th scope="col" style="width: 35"> {{log.ctime}}  </th>
                            <th scope="col" style="width: 35%">
                                <button class="btn btn-secondary p-2" v-on:click="StartDownloading(log.name)">
                                    Download
                                </button>  
                            </th>   
                        </tr>
                </tbody>
            </table>
        </stack-modal>
        </div>

    </div>
</template>

<script>
import axios from 'axios'
import StackModal from '@innologica/vue-stackable-modal'
const FileDownload = require('js-file-download');
export default {
    components: { StackModal },
    data(){
        return{
            logfiles:['name'],
            showDownloadModal: false,
            domains:[{username:'name'}, {usertype:'domain'},{id:'org id'}, {workplaces:'workplaces'}, {addNew:'addNew'}, {remove:'remove'}],
            logfiles:['name'],
            saveButtonDefaults:{
                title: 'Save',
                visible: false,
                btnClass: {'btn btn-primary': true}
            },
            cancelButtonDefaults:{
                title: 'Ok',
                visible: true,
                btnClass: {'btn btn-secondary': true}
            }
        }
    },
    props:{
            isLoggedIn:Boolean,
            usertype:String,
            accesstoken:String,
            orgs:Array
    },
    mounted(){
        axios.get(this.$address+"api/Organisations?access_token="+this.token)
            .then(response => {
                this.domains=response.data
                console.log(response.data); 
                this.domains.forEach(element => { 
                    console.log("org=");
                    console.log(element.id); 
                    axios.get(this.$address+"api/Organisations/"+element.id+"/workplaces/count?access_token="+this.token)
                        .then(response => {
                            console.log(response.data)
                            element.workplaces=response.data.count;
                        });
                });  
            })
    },
    methods :{
        DownloadLogFile(){ //listing of the data for showin in modal
            axios.get(this.$address+'api/Files/log/files?access_token='+this.accesstoken).then(response=>{
                console.log(response.data);
                this.logfiles=(response.data);
            })
        },
        StartDownloading(filename){
            axios.get(this.$address+'api/Files/log/download/'+filename+'?access_token='+this.accesstoken).then(response=>{
                FileDownload(response.data);
            })
        }
    }
}
</script>
<style lang="css" scoped>

</style>