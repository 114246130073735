<template><!--LOGIN SECTION-->
    <div>
        <div class="d-flex justify-content-end"> 
            <div class="mx-auto">
                <div id="pswrcvr" style="display:block;" v-if="!!ifRecovery()">
                    <ResetPassword></ResetPassword><br>
                </div>
                <!--form id="loginForm" style="display:block;" v-if="!ifRecovery()">
                    <h1 align="center">Users Login</h1>
                    <br>
                    <input type="text" class="form-control" placeholder="Name" v-model="username">
                    <br>    
                    <input type="password" @keyup.enter="login" class="form-control" placeholder="Password" v-model="password">
                    <br>
                    <button type="button" class="btn btn-primary btn-block" id="loginBtn" v-on:click="login">Login</button>
                </form-->
                <h1 align="center">User Management</h1>
                <br>
                <button class="btn btn-info btn-block" v-on:click="getAuthToken">Login with MyId</button>
            </div>
        </div>
        <stack-modal :show="showLoginError" title="Error 💥" :saveButton=saveButtonDefaults :cancelButton=cancelButtonDefaults @close="showLoginError=false">
        Wrong username, password or not sufficient permissions, please try again
        </stack-modal>
    </div>
</template>

<script>
    import axios from 'axios'
    import StackModal from '@innologica/vue-stackable-modal'
    import ResetPassword from '@/components/Settings/ResetPassword'
    export default{    
        name: "StackableModalDemo",
        components: { 
            StackModal,
            ResetPassword  
        },      
        props:{
            prop:['test']
        },
        data (){
            return{
                showLoginError:false,
                address:"to be specified", //loopback access point
                username:"",
                password:"",
                usertype:"",
                email:"email@domain.com",
                organization:"",
                saveButtonDefaults:{
                    title: 'Ok',
                    visible: false,
                    btnClass: {'btn btn-primary': true,
                    onClick:"return sampleFunction()"}
                },
                cancelButtonDefaults:{
                    title: 'Ok',
                    visible: true,
                    btnClass: {'btn btn-secondary': true}
                }
            }
        },
        methods:{
            sampleFunction()
            { 
                alert("abc"); 
                return false;
            },
            login(){
                const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

                const usr = this.username.split(" ").reduce((sum, toAdd)=>sum+toAdd);
                const empty = [" ", "\n", "\t"];

                const endSpaces = (pss)=> empty.includes(pss.charAt(pss.length-1))?
                        endSpaces(pss.substring(0,pss.length-1)):
                        pss;

                const initSpaces = (pss)=>
                    empty.includes(pss.charAt(0))?
                        initSpaces(pss.substring(1)):
                        endSpaces(pss);
                
                const p = initSpaces(this.password);
                let credentials={};
                if(usr.match(mailformat))
                    credentials={
                        email: usr,
                        password:p
                    }
                else
                    credentials={
                        username: usr,
                        password:p
                    }
                axios.post(this.$address+'api/UserAccounts/login', credentials).then(response=>{
                    this.token = response.data.id
                    this.userId=response.data.userId;   

                    axios.get(this.$address+'api/UserAccounts/'+this.userId+'?access_token='+this.token, credentials).then(response2=>{ 
                        
                        if(response2.data.usertype=="admin"|| response2.data.usertype=="manager"){

                            axios.get(this.$address+'api/UserAccounts/'+this.userId+'?access_token='+this.token, credentials).then(response3=>{
                                this.$emit("changeTokenValue",this.token, this.$address);
                                this.$emit("SetUpUsertype",response3.data);
                                document.getElementById("loginForm").style.display = "none"
                                document.getElementById("pswrcvr").style.display = "none"
                                //document.getElementById("logoutForm").style.display = "block"
                            })

                        }else{
                            console.log("access denied for werker")
                            this.showLoginError=true;
                        }
                    })

                },
	                error => { 
                        this.showLoginError=true;
                    })          
            },
            ifRecovery(){
                return !(this.$route.query.rectoken == null || this.$route.query.rectoken == undefined || this.$route.query.rectoken == "");
                //return this.$route.query.rectoken;
            },
            getAuthToken(){
                axios.post(this.$address+'api/auth0s/authenticateURL').then(res=>{
                    console.log(res.data);
                    window.location.href = (res.data);
                },
                err=>{
                    console.log(err);
                })
            }
        }
    }
</script>
<style lang="css" scoped>

</style>