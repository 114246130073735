<!-- USER LOGIN & REGISTRATION SECTION -->
<template>
    <div class="container my-3">
        <br>
        <div class="container">
            <div class="row">
                <div class="col-3">
                    <h3>User Management</h3>
                </div>
                <div class="col-6"/>
                <div class="col-2">
                    <input type="text" class="form-control" id="Search" placeholder="Search" v-model="search" v-on:keyup="searchBy()">
                </div>
            </div>
            <br>
            <div class="row form-group">
                <div class="col-3">
                    <input type="email" class="form-control" id="emailInput" placeholder="email" v-model="email">
                </div>
                <div class="col">  
                    <select id="dropdown" class="custom-select" v-model="inputUserType">
                        <option disabled selected>User Type</option>
                        <option value='werker'>werker</option>
                        <option value="manager">manager</option>
                        <option v-if="usertype === 'admin'" value="admin">admin</option>
                    </select>
                </div>

                <div class="col" v-if="usertype === 'admin'">
                    <select class="custom-select" v-model="organisation">
                        <option disabled selected>Organization</option>
                        <option v-for="(entry,index) in domains" :value="entry.id" :key="index">
                            {{entry.name}}
                        </option>
                    </select>
                </div>

                <div class="col">
                    <input type="password" class="form-control" id="passwordInput" placeholder="Auto-Password" v-model="password" disabled>
                </div>
                <div class="col">
                    <button class="btn btn-outline-info" v-on:click="CreateNewUser">Create</button>
                </div>                     
            </div>
            <br>
            <div class="row">
                <div class="col-8">
                    <div class="row">
                        <div class="col-5"><h4 class="text-secondary">Email</h4></div>
                        <div class="col"><h4 class="text-secondary">User type</h4></div>
                        <div v-if="usertype === 'admin'" class="col">
                            <h4 class="text-secondary">Organization</h4>
                        </div>
                        <!--div class="col"><h4 class="text-secondary">Password</h4></div-->
                        <div class="col-1"><h4 class="text-secondary">Delete</h4></div>
                    </div>
                </div>
            </div>
            <br>

            <div class="row">
                <div class="col-8">
                    <div v-for="(entry, index) in fields" v-bind:index="index" :key="index">
                        <div class="row">
                            <div class="col-5">
                                <p class="text-info">{{entry['email']}}</p>
                            </div>
                            <div class="col">
                                <p class="text-info">{{entry['usertype']}}</p>
                            </div>
                            <div v-if="usertype === 'admin'" class="col">
                                <p class="text-info">{{entry['organizationName']}}</p>
                            </div>
                            <!--div class="col">
                                <button type="button" v-on:click="resetPassword(entry.email)" class="btn btn-outline-info">Reset password</button>
                            </div--> 
                            <div class="col-1">
                                <button v-if="usertype!='admin' && entry['usertype']=='admin'" type="button" class="btn btn-outline-info" disabled>🗑</button >
                                <button v-else type="button" v-on:click="deleteUser(entry.id, index)" class="btn btn-outline-info">🗑</button >
                            </div>
                            <br>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <dragAndDropUsers :token="token" :Orga="organisationID" @listNewUser="addUserToList"/>
                </div>
            </div>

            <br>
            <div class="row">
                <div class="col-3">
                    <button v-on:click="changePage(band-1)" class="form-control">
                        <code style="color:black">&lt;</code>
                    </button>
                </div>
                <div class="col-2">
                    <p class="text-info" style="text-align: center;">
                    Page: {{band+1}}/{{pages}}
                    </p>
                </div>
                <div class="col-3">
                    <button v-on:click="changePage(band+1)" class="form-control">
                        <code style="color:black">&gt;</code>
                    </button>
                </div>
            </div>
            
        </div>

        
        <stack-modal :show="showWrongEmailModal" title="Error while creating the user" :saveButton=saveButtonDefaults @close="showWrongEmailModal=false">
           Please check:
           1. If you provided all required data
           2. If there're no duplicaitons in the database

           And try again, wish you good luck! 🍀
        </stack-modal>
        <stack-modal :show="cantRemoveAdmin" title="Error" :saveButton=cantRemoveSave :cancelButton=cantRemoveCancel @close="cantRemoveAdmin=false">
            Admin cannot be removed
        </stack-modal>
        <stack-modal :show="this.resetPass" :saveButton=saveButtonDefaults @close="resetPass=false">
            Password recovery email was sent to user
        </stack-modal>
    </div> 
</template>

<script>
import axios from 'axios'
import StackModal from '@innologica/vue-stackable-modal'
import dragAndDropUsers from '@/components/Components/dragAndDropUsers'
    export default {
        components: {
            StackModal,
            "dragAndDropUsers" : dragAndDropUsers
        },
        props:{
            token:String,
            usertype:String,
            organisationID:String
            //id:String
        },
        data(){
            return{
                address:"to be specified", //loopback access point
                showWrongEmailModal: false,
                cantRemoveAdmin:false,
                //posts:[],
                name:"",
                email:"",
                search:"",
                description:"",
                username:"",
                password:"",
                organisation:"",
                order: "default",
                fields:[{username:'username'}, {usertype:'usertype'}, {email:'email'},{organisationID:'organisationID'}, {password:'password'},{action:'action'}],
                domains:[{username:'name'}, {usertype:'domain'},{id:'org id'}, {workplaces:'workplaces'}, {addNew:'addNew'}, {remove:'remove'}],
                inputUserType:"",
                orgs:[{id:'id',name:'name'}],
                saveButtonDefaults:{
                    title: 'Ok',
                    visible: false,
                    btnClass: {'btn btn-primary': true}
                },       
                cantRemoveSave:{
                    title: 'Ok1',
                    visible: false,
                    btnClass: {'btn btn-secondary': true,
                    onClick:"return false"}
                },     
                cantRemoveCancel:{
                    title: 'Ok',
                    visible: true,
                    btnClass: {'btn btn-secondary': true}
                },
                resetPass: false,
                band: 0,
                searchStat:0,
                bandChanging:false,
                UsersLimit:20,
                pages:1
            }
        },
        mounted(){
            this.getUsers();
            if(this.usertype === 'admin')
                axios.get(this.$address+"api/Organisations?access_token="+this.token)
                    .then(response => {
                        this.domains=response.data;
                    }
                );

            axios.get(this.$address+'api/UserAccounts/count?access_token='+this.token,
                this.usertype=="admin"?
                    {}:
                    {"params":{"where":{"organisationID":this.organisationID}}}
            ).then(
                (res)=>{this.pages = Math.ceil(res.data.count/this.UsersLimit)},
                (err)=>{console.log(err)}
            );
        },
        methods:{
            CreateNewUser(){
                const org =(this.usertype == "admin")?this.organisation:this.organisationID;
                const vals={
                    "usertype":this.inputUserType,
                    "email":this.email,
                    "password":this.password != null && this.password != "" && this.usertype == "admin"? this.password: Math.round(Math.random()*8999 + 1000).toString(10),
                    "organisationID":org
                }
                //console.log(vals);
                if(this.email!=""){
                    axios.post(this.$address+'api/UserAccounts?access_token='+this.token, vals).then(response=>{
                        this.getUsers();
                        this.clearInput()
                    }, 
                    (error) => { 
                        this.showWrongEmailModal=true;
                        this.clearInput();
                    })
                }
                else
                    this.showWrongEmailModal=true;
            },            
            deleteUser(id, index){       
                axios.get(this.$address+"api/UserAccounts/"+id+"?&access_token="+this.token)
                .then(response => {
                    if(response.data.usertype=="admin"){
                        console.log("can't remove admin")
                        this.cantRemoveAdmin=true;
                    }
                    else {
                        console.log("removing user")
                        axios.delete(this.$address+'api/UserAccounts/'+id+"?access_token="+this.token).then(response=>{
                            this.fields.splice(index, 1) //delete the post

                        })                
                    }
                })
            },
            clearInput(){
                this.username=''
                this.password=''
                this.email=''
                this.organisation=''
            },/*
            resetPassword(id){
                const data={
                    userID:id,
                }
                this.showAddModal=true;
                this.resetPass = true;
                axios.post(this.$address+'api/UserAccounts/resetPasswordRequest?access_token='+this.token, data).then(response=>{
                    console.log(response)
                    this.refreshOrgList();
                })
            },*/
            isSearched(mail){
                return this.search == mail || mail.includes(this.search);
            },
            refreshOrgList(){
                axios.get(this.$address+"api/Organisations?access_token="+this.token)
                .then(response => {
                    this.domains=response.data;
                });
            },
            getOrg(org){
                for (let x = 0; x < this.domains.length; x++){
                    if(this.domains[x].id == org) return(this.domains[x]);
                }
                return("-");
            },
            addUserToList(usr){
                this.fields.push(usr);
            },
            getUsers(){
                const params = {
                    index:this.band,
                    order:"ASCD",
                    filter:{
                        "userType":this.usertype,
                        "organization":this.organisationID,
                        "searchBy":this.search.toLowerCase()
                    }
                }
                
                axios.get(this.$address+'api/UserAccounts/usersList?access_token='+this.token, {"params":params}).then((res)=>{
                    this.fields = res.data
                    //console.log(this.fields);
                    this.bandChanging = false
                },
                (err)=>{
                    console.log(err);
                    this.bandChanging = false
                });
            },
            searchBy(){
                console.log(this.search);
                const order = ++this.searchStat;
                const compare=(word)=>{
                    if(word != this.search || order != this.searchStat)
                        return;
                    this.band = 0;
                    this.getUsers();
                }

                setTimeout(compare, 1000, this.search);
            },
            changePage(indx){

                const param = this.usertype=="admin"?{}:{"params":{"where":{"organisationID":this.organisationID}}};
                axios.get(this.$address+'api/UserAccounts/count?access_token='+this.token,param).then(
                    (res)=>{
                        this.pages = Math.ceil(res.data.count/this.UsersLimit);
                        //console.log(res.data.count);
                        const limit=(indx)*this.UsersLimit;
                        //console.log(limit);
                        if(limit < res.data.count && indx >= 0 && !this.bandChanging){
                            this.bandChanging = true;
                            this.band=indx;
                            this.getUsers();
                        }
                    },
                    (err)=>{
                        console.log(err);
                    }
                )
            }
        }
    }
</script>

<style lang="css" scoped>
    .btn-outline-info{
        border-color: #156E8E !important;
        color: #156E8E !important;
    }
    .text-info{
        color: #156E8E !important;
    }
</style>