<template>
  <div id="app">
    <router-view>
    </router-view>
    <script type="text/javascript" src="../bport.json"></script>
  </div>
</template>

<script>
import 'vue-select/dist/vue-select.css';
export default {
  name: 'app',
  created(){
    if (location.protocol !== 'https:') {
      location.replace(`https:${location.href.substring(location.protocol.length)}`);
    }

  },
  head:{
     "link":[
       { 
         "rel": 'icon', 
         "href": window.location.hostname == "task2fit.helpmee.siemens.cloud" || window.location.hostname == "arexperts.helpmee.siemens.cloud"?
            require('../../assets/tabTask2fit.png'): require('../../assets/GiriLogo.png'),
         "type": 'image/png' 
        }
     ]
  }
}
</script>

<style lang="css" scoped>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
}
@import'~bootstrap/dist/css/bootstrap.css'
</style>
