<template>
    <div>
        <button align="right" class="buttn" v-on:click="helpStack=true">
                Help
                <img src="../../../../assets/help.png" alt="" style="vertical-align:top; max-width:20px; max-height:20px">
        </button>
        <div class="dragContainer" id="app">
            <div class="filesContainer" id="FilesContainer" @drop="drop" @dragover="dragover" @dragleave="dragleave">
                <input hidden type="file" multiple name="fields[assetsFieldHandle][]" :forceFallback="true" id="assetsFieldHandle" @change="onChange" ref="file" />
                <label for="assetsFieldHandle" class="block cursor-pointer">
                    <div class="dragText">
                        <img src="../../../../assets/Upload.png" width="50" height="50">
                        <br>
                        <span class="underline text-secondary">Drag and drop the CSV table file to upload multiple users.</span>
                    </div>
                </label>
            </div>
        </div>
        

        <stack-modal :show="onDoneUpload" @close="onDoneUpload=false" :saveButton="saveButton" :cancelButton="cancelButton">
            <h4 class="text-secondary text-center">{{stackMessage}}</h4>
            <div v-if="errorList.length >= 1">
                <p>The accounts could not be created.</p>
                    <p class="text-secondary" v-for="fls in errorList">
                        {{(typeof(fls)=="object"?"name" in fls? fls.name:fls:fls)+" "+(typeof(fls)=="object"?"error" in fls? fls.error:"":"")}}
                    </p>
                <p>Please check</p>
                <ul>
                    <li><p>Whether the table is formatted correctly</p></li>
                    <li><p>If all e-mails addresses are written correctly</p></li>
                    <li><p>That none of the emails already have an account</p></li>
                    <li><p>The file is not bigger than 30 Mb</p></li>
                </ul>
            </div>
        </stack-modal>

        <stack-modal :show="helpStack" @close="helpStack=false" :saveButton="saveButton" :cancelButton="cancelButton">
            <h3>Create a CSV table</h3>
            <p>
                To create a larger number of new accounts, you can upload a csv list.
            </p>
            <p>
                Csv files can be created with e.g. Excel, Google Sheets or the standard editor.
            </p>

            <div class="container">
                <div class="row">
                    <div class="col">
                        <img src="../../../../assets/exampleEditor.JPG.jpg" alt="">
                        <p></p>
                    </div>
                    <div class="col">
                        <img src="../../../../assets/exampleGoogleSheets.JPG.jpg" alt="">
                    </div>
                </div>
            </div>
        </stack-modal>
    </div>
</template>

<script>
import { log } from 'three';
import StackModal from '@innologica/vue-stackable-modal'
import axios from 'axios'
    export default{
        components: {
            StackModal
        },
        data() {
            return{
                filelist: [], // Store our uploaded files
                errorList:[],
                API: 'https://localhost'+'/api',
                onDoneUpload: false,
                saveButton:{visible: false},
                cancelButton:{
                    title: 'Ok',
                    visible: true,
                    btnClass: {'btn btn-primary ': true}
                },
                correctUploaded:0,
                errorUploaded:0,
                stackMessage:"Uploading Files",
                cancelUpload: false,
                helpStack: false
            }
        },
        beforeMount(){
            axios.get(this.$address+'api/utilities/serverFeatures').then(res=>{                 
                this.serverFeatures = res.data.result ;
                console.log(this.serverFeatures)
                console.log(typeof this.serverFeatures.SSO)
            })
        },
        props:{
            token:String,
            Orga:String
        },
        methods: {
            onChange() {
                this.filelist = Object.entries(this.$refs.file.files).map((a)=>{return a[1]});
                this.uploadList();
            },
            drop(event) {
                event.preventDefault();
                this.$refs.file.files = event.dataTransfer.files;
                this.onChange(); // Trigger the onChange event manually
            // Clean up
                event.currentTarget.classList.add('bg-gray-100');
                event.currentTarget.classList.remove('bg-green-300');
            },
            dragover(event) {
                event.preventDefault();
                // Add some visual fluff to show the user can drop its files
                if (!event.currentTarget.classList.contains('bg-green-300')) {
                    event.currentTarget.classList.remove('bg-gray-100');
                    event.currentTarget.classList.add('bg-green-300');
                }
            },
            dragleave(event) {
                    // Clean up
                    event.currentTarget.classList.add('bg-gray-100');
                    event.currentTarget.classList.remove('bg-green-300');
                },
            uploadList(){
                this.cancelButton.title = "Cancel"

                this.stackMessage = "Uploading Users";
                if(this.filelist.length <= 0){  
                    this.stackMessage = "Please check your .csv file of errors.";
                    return;
                }
                
                this.onDoneUpload = true;

                this.errorList = [];

                const sizeLimit = Math.pow(1024,3);

                /**
                 * @fullCsv {string = } Full users table text
                 */
                const getUsersList=(fullCsv)=>{
                    let rows = fullCsv.split('\r\n');
                    if(rows.length <= 1){
                        rows = fullCsv.split('\n');
                    }
                    console.log(rows);
                    if(rows.length < 2)
                        return [];
                    
                    const head = rows.shift().split(",");
                    
                    if(head.length == 2?(head[0]!="email" || head[1] != "user type"):true)
                        return [];

                    const users = rows.map(user=>{
                        const usrSpl = user.split(","); 

                        if(usrSpl.length<2)
                            return {
                                user:usrSpl[0],
                                type:"error",
                                error:"Error in row Length"
                            }

                        if(usrSpl.length>2)
                            return {
                                user:usrSpl[0],
                                type:usrSpl[1],
                                error:"Error in row lenght"
                            }

                        const temp = usrSpl[0].split("@");
                        if(temp.length != 2? true: temp[1].split(".").length <= 1)
                            return {
                                user:usrSpl[0],
                                type:usrSpl[1],
                                error:"Error in email"
                            }

                        const temp2 = ["werker", "manager"];
                        if(!temp2.includes(usrSpl[1]))
                            return{
                                user:usrSpl[0],
                                type:usrSpl[1],
                                error:"Error in user type"
                            }
                        
                        return{
                            user:usrSpl[0],
                            type:usrSpl[1]
                        }
                    });

                    console.log(users);
                    return users;
                }

                const onUploadError = (res, info)=>{
                    this.errorList.push(info.email);
                }

                const getFile = (uploadFileFunc)=>{
                    if(this.filelist.length >0){
                        let fr = new FileReader();
                        fr.onload = uploadFileFunc;
                        fr.readAsText(this.filelist.shift());
                    }else{
                        this.cancelButton.title = "Ok"
                        this.stackMessage = "Done";
                        return;
                    }
                }

                if(this.filelist.length <= 0){
                    return;
                }
                
                const uploadFile = (event)=>{
                    if(!this.onDoneUpload)
                        return;

                    const fullUsers = getUsersList(event.target.result);
                    const validUsers = fullUsers.filter(us => {
                        return !(us.hasOwnProperty("error"))
                    });

                    if(validUsers <= 0){
                        this.errorList.push({"name":"Please check your .csv files for errors."})
                        this.stackMessage = "Something is wrong";
                        return;
                    }

                    fullUsers.forEach(us => {
                        if(us.hasOwnProperty("error"))
                            this.errorList.push(us.user);
                        }
                    );
                    
                    let toUpload = validUsers.map(user=>{
                        return {
                            "usertype": user.type,
                            "organisationID": this.Orga,
                            "email": user.user,
                            "emailVerified": true,
                            "password": (Math.floor(Math.random() * 8999) + 1000).toString(),
                            "csv":this.serverFeatures.SKIP_CSV 
                        }
                    });

                    const uploadList = ()=>{

                        if(toUpload.length>0){
                            axios.post(this.API + '/UserAccounts?access_token='+this.token, toUpload[0]).then(
                            (res)=>{
                                console.log(res.data);
                                this.$emit("listNewUser", res.data);
                                this.correctUploaded++;
                                toUpload.shift();
                                uploadList();
                            },
                            (err)=>{
                                this.errorUploaded++;
                                onUploadError(err, toUpload.shift())
                                uploadList();
                            });
                        }else{
                            getFile(uploadFile);
                        }
                    }
                    uploadList();
                }
                getFile(uploadFile);
            }
        },
        mounted(){
            var domain=self.location.host;
            if(domain =="localhost" || domain=="giri" || domain=="127.0.0.1"){
                console.log("On premise server");
                this.API="https://localhost/api";
            }else{
                console.log("Cloud server");
                this.API="https://"+self.location.host+"/api"
            }
        }
    }
</script>

<style lang="css" scoped>
    .dragContainer{
        flex:content; 
        align-items:center; 
        justify-content: center;
        text-align:center;
    }

    .filesContainer{
        border-style: dashed;
        border-color: rgb(116, 116, 116);
        border-width: 2px;
        border-radius: 10px;
    }

    .filesContainer:hover{
        border-style: dashed;
        border-color: rgb(146, 146, 146);
        border-width: 2px;
        border-radius: 10px;
        background-color: rgb(237, 247, 255);
    }

    .dragText{
        padding-top: 4vh;
        padding-bottom: 4vh;
    }

    .buttn{
        background-color: transparent;
        border: none;
        text-align: center;
        float: right;
        color: gray;
        margin-top: 4px;
    }
</style>