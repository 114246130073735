<template>
    <div>
        <div class="dragContainer" id="app">
            <div class="filesContainer" id="FilesContainer" @drop="drop" @dragover="dragover" @dragleave="dragleave">
                <input hidden type="file" multiple name="fields[assetsFieldHandle][]" :forceFallback="true" id="assetsFieldHandle" @change="onChange" ref="file" />
                <label for="assetsFieldHandle" class="block cursor-pointer">
                    <div class="dragText">
                        <img src="../../../../assets/Upload.png" width="50" height="50">
                        <br>
                        <span class="underline text-secondary">Drag and drop the assetbundles to upload.</span>
                    </div>
                </label>
            </div>
        </div>
        

        <stack-modal :show="onDoneUpload" @close="onDoneUpload=false" :saveButton="saveButton" :cancelButton="cancelButton">
           <div v-if="errorList.length >= 1">
                <h4 class="text-secondary text-center">File not uploaded</h4>
                
                <p>The following files could not be uploaded:</p>
                    <p class="text-secondary" v-for="fls in errorList">
                        {{typeof(fls)=="object"?"name" in fls? fls.name:fls:fls}}
                    </p>
                <p>Please check the requirements of the file format:</p>
                <ul>
                    <li><p>The file is an asset bundle handle</p></li>
                    <li><p>The file name has the suffix _ios or _android, for example: arrow_green_ios</p></li>
                    <li><p>It is not possible to load assets that have the same name as the defaults</p></li>
                    <li><p>The file is not bigger than 30 Mb</p></li>
                </ul>
            </div>
            <div v-else>
                <h4 class="text-secondary text-center">All changes have been saved correctly</h4>
            </div>
        </stack-modal>
    </div>
</template>

<script>
import StackModal from '@innologica/vue-stackable-modal'
import axios from 'axios'
    export default{
        components: { StackModal },
        data() {
            return{
                filelist: [], // Store our uploaded files
                errorList:[],
                API: 'https://localhost'+'/api',
                onDoneUpload: false,
                showModal: false,
                saveButton:{visible: false},
                cancelButton:{
                    title: 'Ok',
                    visible: true,
                    btnClass: {'btn btn-primary ': true}
                }
            }
        },
        props:{
            token:String,
            Orga:String,
            defaultObjs: Array
        },
        methods: {
            onChange() {
                this.filelist = Object.entries(this.$refs.file.files).map((a)=>{return a[1]});
                this.uploadList();
            },
            drop(event) {
                event.preventDefault();
                this.$refs.file.files = event.dataTransfer.files;
                this.onChange(); // Trigger the onChange event manually
            // Clean up
                event.currentTarget.classList.add('bg-gray-100');
                event.currentTarget.classList.remove('bg-green-300');
            },
            dragover(event) {
                event.preventDefault();
                // Add some visual fluff to show the user can drop its files
                if (!event.currentTarget.classList.contains('bg-green-300')) {
                    event.currentTarget.classList.remove('bg-gray-100');
                    event.currentTarget.classList.add('bg-green-300');
                }
            },
            dragleave(event) {
                    // Clean up
                    event.currentTarget.classList.add('bg-gray-100');
                    event.currentTarget.classList.remove('bg-green-300');
                },
            uploadList(){
                if(this.filelist.length <= 0)    
                    return;
                
                this.errorList = [];

                const obj={
                    "organizationID":this.Orga,
                    "timestamp": new Date().toISOString(),
                    "filepath": "Files/giri_objects",
                    "isActive": true,
                }

                const sizeLimit = Math.pow(1024,3);

                //Verify the file is correct
                const verifyFile = (fileName, toSend)=> {
                    if(fileName.length <= 0 && typeof(fileName) != 'string')
                        return  null;

                    const slpt = fileName.split("_");
                    if(slpt.length > 1){
                        const lastValue = slpt[slpt.length-1];
                        const SOList = [
                            "ios",
                            "android",
                            "windows"
                        ];
                        if(!SOList.includes(lastValue.toLowerCase()))
                            return null;

                        let word = slpt[0];
                        for(let x = 1; x < slpt.length-1; x++){
                            word = word + " " + slpt[x];
                        }

                        toSend.opSystem = lastValue;
                        toSend.symbolID = word;
                        return toSend;
                    }else
                        return null;
                }

                const onUploadError = (res)=>{
                    const nxtFile = this.filelist.shift();
                    this.errorList.push(nxtFile);
                    if('data' in res)
                        axios.delete(this.API + '/objects/'+res.data.id+'?access_token='+this.token);
                }

                const onUploadedCorrect = ()=>{
                    this.filelist.shift();
                }

                const genFile = (id = '')=> {
                    if (id.length === 0) return;
                    const fd = new FormData();
                    fd.append('file', this.filelist[0], id);
                    return fd;
                }

                const setObject = ()=>{
                    if(this.filelist.length <= 0){
                        if (this.showModal || this.errorList.length >= 1) this.onDoneUpload = true;
                        return;
                    }
                    const nxtValue = verifyFile(this.filelist[0].name, obj);
                    if(nxtValue != null && this.filelist[0].size < sizeLimit && !this.defaultObjs.includes(nxtValue.symbolID)) {
                        axios.get(`${this.API}/objects?access_token=${this.token}`, { params: {
                            filter: { where: { and: [{ symbolID: nxtValue.symbolID }, { opSystem: nxtValue.opSystem }, { organizationID: this.Orga }] } }
                        }})
                        .then((query) => {
                            if (query.data?.length === 0) {
                                axios.post(`${this.API}/objects?access_token=${this.token}`, nxtValue)
                                .then((res) => {
                                    axios.post(`${this.API}/Files/giri_objects/upload?access_token=${this.token}`, genFile(res.data.id))
                                    .then(() => {
                                        if (this.filelist.length === 1) this.$emit("object_adeed", res.data);
                                        this.showModal = true;
                                        onUploadedCorrect();
                                        setObject();
                                    })
                                    .catch(err => {
                                        console.error(err);
                                        onUploadError(res);
                                        setObject();
                                    });
                                })
                                .catch(err => {
                                    console.error(err);
                                    onUploadError({});
                                    setObject();
                                });
                            } else {
                                this.$bvModal.msgBoxConfirm(
                                    `${nxtValue.symbolID} already exists, do you want to replace it? This change will affect all instructions using this asset.`,
                                    {
                                        title: "Warning",
                                        okVariant: 'danger',
                                        okTitle: 'YES',
                                        cancelTitle: 'NO',
                                    }
                                )
                                .then((value) => {
                                    if (value) {
                                        const { id } = query.data[0];
                                        axios.post(`${this.API}/Files/giri_objects/upload?access_token=${this.token}`, genFile(id))
                                        .then(() => {
                                            this.showModal = true;
                                            onUploadedCorrect();
                                            setObject();
                                        })
                                        .catch((err) => {
                                            console.error(err);
                                            onUploadedCorrect();
                                            setObject();
                                        });
                                    } else {
                                        onUploadedCorrect();
                                        setObject();
                                    }
                                });
                            }
                        });
                    }
                    else{
                        onUploadError({});
                        setObject();
                    }
                }
                this.showModal = false;
                setObject();
            }
        },
        mounted(){
            var domain=self.location.host;
            if(domain =="localhost" || domain=="giri" || domain=="127.0.0.1"){
                console.log("On premise server");
                this.API="https://localhost"+"/api";
            }else{
                console.log("Cloud server");
                this.API="https://"+self.location.host+"/api"
            }
        }
    }
</script>

<style lang="css" scoped>
    .dragContainer{
        flex:content; 
        align-items:center; 
        justify-content: center;
        text-align:center;
    }

    .filesContainer{
        border-style: dashed;
        border-color: rgb(116, 116, 116);
        border-width: 2px;
        border-radius: 10px;
    }

    .filesContainer:hover{
        border-style: dashed;
        border-color: rgb(146, 146, 146);
        border-width: 2px;
        border-radius: 10px;
        background-color: rgb(237, 247, 255);
    }

    .dragText{
        padding-top: 150px;
        padding-bottom: 150px;
    }


</style>