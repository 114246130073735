import Vue from 'vue'
import Router from 'vue-router'
import VueHead from 'vue-head'
import Homepage from '@/components/Homepage'
import Siemens_Homepage from '@/components/Siemens_Homepage'
import Login from '@/components/Login'
import getPort from '@/components/getPort'

var domain = self.location.host;
const t2f = domain == "task2fit.helpmee.siemens.cloud" || domain == "arexperts.helpmee.siemens.cloud";
const title = t2f? 'Task2Fit':'Giri';
const homepage = t2f? Siemens_Homepage: Homepage;

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/workflows',
      component: () => import("@/components/PublicWorkflow/Workflow"),
      props: {
        girimed: false,
        route: route => ({ query: route.query.public_id })
      },
      meta:{
        title: 'Schau dir diese einfache Anleitung von '+title+' an!',
        metaTags:[
          {
            property: 'og:title',
            content: 'Schau dir diese einfache Anleitung von '+title+' an!'
          }
        ]
      }
    },
    {
      path: '/giri-med-workflows',
      component: () => import("@/components/PublicWorkflow/Workflow"),
      props: {
        girimed: true,
        route: route => ({ query: route.query.public_id })
      }
    },
    {
      path: '/siemens-workflows',
      component: () => import("@/components/PublicWorkflow/Workflow"),
      props: {
        siemens: true,
        route: route => ({ query: route.query.public_id })
      }
    },
    {
      path: '/',
      name: 'Homepage',
      component: homepage,
      meta:{
        title: title + ' User Management Dashboard',
        metaTags:[
          {
            property: 'og:title',
            content: title + ' User Management Dashboard'
          }
        ]
      }
    },
    {
      path: '/AdminTools',
      name: 'AdminTools',
      component: homepage
    },
    {
      path: '/login',
      name: 'Login',
      component: homepage
    },
    {
      path: '/PortSettings/1235/',
      name: 'p',
      component: getPort
    },
    {
      path: '/Settings/ResetPassword',
      component: () => import("@/components/Settings/ResetPassword"),
      name: "ResetPassword"
    },
    {
      path: '/printable-version',
      name: 'WorkflowPdf',
      component: () => import("@/components/Printable/WorkflowPdf")
    },
    {
      path: '/reset-password',
      name: 'ResetPassword',
      component: () => import("@/components/Settings/RequestPassword")
    }
  ]
})


router.beforeEach((to, from, next) => {
  
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  if(nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));
  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();
  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');
    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });
    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');
    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));
  next();
});

export default router
Vue.use( Router )
Vue.use( router )
Vue.use(VueHead)
