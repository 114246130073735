<template>
    <div>
        <b-navbar toggleable="lg" type="light" variant="light">
            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav>
                    <!--<b-nav-item v-on:click="emitCustomMenuChange('basics')">
                        Basics
                    </b-nav-item>-->
                    <b-nav-item v-on:click="emitCustomMenuChange('paterns')">
                        Patterns
                    </b-nav-item>
                    <b-nav-item v-on:click="emitCustomMenuChange('objects')">
                        AR Objects
                    </b-nav-item>
                    <b-nav-item v-on:click="emitCustomMenuChange('icons')">
                        Industrial Icons
                    </b-nav-item>
                    <b-nav-item v-on:click="emitCustomMenuChange('WfPasswordmanager')">
                        PasswordManager
                    </b-nav-item>
                    <b-nav-item
                        v-if="usertype === 'orgAdmin' || usertype === 'demoAdmin'"
                        v-on:click="emitCustomMenuChange('WhiteLabel')"
                    >
                        White Label
                    </b-nav-item>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>
</template>

<script>
    export default {
        props: {
            usertype: {
                type: String,
                required: true,
            }
        },
        methods:{
            emitCustomMenuChange(nextTab){
                this.$emit("ChangeCustMenu", nextTab);
            }
        },
        mounted(){
            //{value:String,label:string}
            console.log(this.menVals);
        }
    }
</script>
<style lang="css" scoped>

</style>