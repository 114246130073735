<template>
    <div>
        <ContentManagementMenu v-on:ChangeCustMenu="ChangeCMMenu($event)"></ContentManagementMenu>
        <div v-if="menu === 'workplaces'">
            <LogoAdmin :Name1="'Logo'" :Name2="'StartScreen'" :title1="'Organization Logo'" :title2="'Start Screen'" :token="token" :Orga="organization"/>
        </div>
        <div v-if="menu === 'workflows'">
        </div>
    </div>
</template>

<script>
import LogoAdmin from '@/components/Tools/LogoAdmin.vue'
import ContentManagementMenu from '@/components/Menus/ContentManagementMenu.vue'
export default {
    components:{
        'LogoAdmin': LogoAdmin,
        'ContentManagementMenu': ContentManagementMenu
    },
    data(){
        return{
            
            menu:"basics"
        }
    },
    props:{
        token:String,
        organization:String
    },
    methods:{
        changeTab(nxt){
            this.menu = nxt;
        }
    }
}
</script>
<style lang="css" scoped>

</style>