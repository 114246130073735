<template>
    <div>
        <div class="container">
            <div class="row">
                <h2 class="title">Add new pattern</h2>
            </div>
            <div class="row">
                <p>
                    Add translations if you want to use giri mobile in different languages. If you don't need all languages, select a default language in which the pattern should be shown if there is no translation.
                </p>
            </div>
        </div>
            <br>
            <div class="container">
            <div class="row">
                <div class="col-1">
                    <p class="text-center">Default</p>
                </div>
            </div>
            <div class="row">
                <div class="col flex-col">
                    <div class="row verticalCenter" v-for="(val,indexParent) in languages" v-bind:key="indexParent">
                        <div class="col-1 verticalCenterBox justify-content-center">
                            <div class="row justify-content-center">
                                <input class="radioButton" type="radio" name="exampleRadios" :id="'radio'+indexParent" :value="indexParent" v-model="defLang">
                                <!-- <label class="form-check-label" for="exampleRadios1">
                                    Default radio
                                </label> -->
                            </div>
                        </div>
                        <div class="col-1 verticalCenterBox">
                            <p>
                                {{val.lang}}
                            </p>
                        </div>
                        
                        <div class="col-2 verticalCenterBox" v-for="(lan, index) in values" v-bind:key="index">
                            <p v-if="indexParent < 1" class="mediaTitle font-weight-lighter">{{index + 1}}.<span class="font-weight-bold">Mediatitle</span></p>
                            <input type="text" placeholder="Add text" class="form-control mediaPattern" v-model="lan[val.cod]"/>
                        </div>
                        <div class="col-2" v-if="indexParent == 2">
                            <div class="row justify-content-left">
                                <button type="submit" class="blueBtn btn" v-on:click="addHeader">
                                    + Add mediatitle
                                </button>
                            </div>
                        </div>
                    </div>
                    
                </div>
                
            </div>
                <div class="d-flex mx-auto mt-4">

                        <button type="submit" class="blueBtn  btn btn-primary btn-block" :disabled="buttStat" v-on:click="createPattern" style="    width:25%;margin-right:5%">
                        Confirm
                        </button>
                        <button type="submit" class="whiteBtn btn btn-primary btn-block" :disabled="buttStat" v-on:click="cancelPattern" style="width:25%; margin-top:0%;">Cancel</button>
                </div>
            
            </div>

        
        <!--<p>{{values}}</p>
        <p>{{organization}}</p>
        <p>{{band}}</p>-->

        <div>
            <stack-modal :show="showError" title="Error 💥" :saveButton=saveButtonDefaults :cancelButton=cancelButtonDefaults @close="showError=false">
                {{errorMassage}}
            </stack-modal>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import StackModal from '@innologica/vue-stackable-modal'
function escapeQuotes(str) {
  return str.replace(/"/g, '\\"');
}
export default {
    components:{
        StackModal
    },
    props:{
        token:String,
        organization:String,
        
    },
    data(){
        return{
            values:[{
                    "en":"",
                    "de":"",
                    "hu":"",
                    "es":"",
                    "pl":"",
                    "ro":""
            }],
            languages:[
                {"lang":"English",
                "cod":"en"},
                {"lang":"Germany",
                "cod":"de"},
                {"lang":"Hungarian",
                "cod":"hu"},
                {"lang":"Spanish",
                "cod":"es"},
                {"lang":"Polish",
                "cod":"pl"},
                {"lang":"Romanian",
                "cod":"ro"},
            ],
            saveButtonDefaults:{
                title: 'Ok',
                visible: false,
                btnClass: {'btn btn-primary': true,
                onClick:"return sampleFunction()"}
            },
            cancelButtonDefaults:{
                title: 'Ok',
                visible: true,
                btnClass: {'btn btn-secondary': true}
            },
            showError:false,
            errorMassage:"",
            classSize:"col-9",
            cols:1,
            address:"",
            defLang:"",
            buttStat:false,
            buttColor:"btn btn-secondary",
            band:"a"
        }
    },
    methods:{
        addHeader(){
            if(++this.cols > 4) return;
            this.values.push({
                    "en":"",
                    "de":"",
                    "hu":"",
                    "es":"",
                    "pl":"",
                    "ro":""
            });
            this.classSize="col-"+Math.floor(9/this.values.length);
        },
        createPattern(){

            this.buttStat = true;

            if(typeof this.defLang != 'number'){
                this.showError=true;
                this.errorMassage = "Please select a default language";
                this.buttStat = false;
                return;
            }
            //generating a list of languages to use
            let langToUse = [];
            let isOk=true;
            const def = Object.keys(this.values[0])[this.defLang];
            let haveDef = false;
            Object.keys(this.values[0]).forEach(element => {
                let value = 0;
                for(let x = 0; x < this.values.length; x++){
                    this.values[x][element] = escapeQuotes(this.values[x][element]);
                    value+= this.values[x][element] != ""?1:0;
                }

                if(value == this.values.length){
                    langToUse.push(element);
                    console.log(element == def);
                    haveDef = haveDef || element == def;
                }
                else if(value != 0){
                    isOk=false;
                    this.showError=true;
                    this.errorMassage = "Some patterns are not completed";
                    this.buttStat = false;
                    return;
                }
            });

            if(!haveDef){
                isOk=false;
                    this.showError=true;
                    this.errorMassage = "The default value is incorrect";
                    this.buttStat = false;
                    return;
            }

            if(!isOk){
                return;
            }


            if(langToUse.length <= 0){
                //in case everithing is empty send a massage that there is no content
                this.showError=true;
                this.errorMassage = "There is no content for the pattern";
                this.buttStat = false;
                return;
            }
            this.band=langToUse;
            //Create all the headerst then the pattern


            //global variables that requires to be accessed
            let _values = this.values;
            let _address = this.$address;
            let _Organization = this.organization;
            let _token = this.token;
            let _defLang = this.defLang;
            let itWorks = true;
            let _names = {};

            const createHeader = (index, lim, idList)=>{
                //create header to send
                //this.band = "hi";
                let head = {
                    'order':(index+1)
                };

                //Add all the steps to an object
                langToUse.forEach(element => {
                    //set name to patterns
                    if(!(element in _names)) _names[element] = _values[index][element];
                    else _names[element] = _names[element] + " - " + _values[index][element];
                    //add header
                    head[element] = _values[index][element];
                });

                //send the header
                
                axios.post(_address + "api/Headers?access_token="+_token, head).then(
                    Response=>{
                        idList.push(Response.data.id);

                        //if is not the last index post the next index
                        if((index+1) < lim) createHeader(index+1,lim, idList);
                        //in case is the last index send the patterns
                        else {
                            let keyf = Object.keys(_values[0]);
                            var patt = {
                                "defaultLanguage": keyf[_defLang],
                                "header": idList,
                                "organisationID": _Organization,
                                "default":false
                            }

                            langToUse.forEach(element =>{
                                patt[element] = _names[element];
                            })

                            axios.post(_address + "api/Patterns?access_token="+_token, patt)
                            .then(
                                Res=>{
                                    itWorks = true;
                                },
                                Err=>{
                                    itWorks = false;
                                }
                            );
                        }
                    },
                    Error=>{
                        itWorks = false;
                        //code to delete each Header
                    }
                );
            };
            //this.band+="d";
            createHeader(0, this.values.length, []);
            
            //when change to Vue 3 try to change this, is only a temporal solution for a render problem
            setTimeout(()=>{
                if(!itWorks){
                    this.errorMassage="problem adding headers or patterns, verify or check your connection"
                            this.showError = true;
                            this.buttStat = false;
                }else{
                    this.buttColor = "btn btn-primary";
                    setTimeout(()=>{
                        this.$emit("pattAdded",true);
                    },1000);
                }
            },2000);
            
        },
        cancelPattern(){
            this.$emit("pattAdded",true);
        }        
    }
}
</script>

<style lang="css" scoped>
.title{
    margin-bottom: 10px!important;
}

.verticalCenterBox{
    display: flex;
    align-items:center;
    margin-bottom: 10px;
}
p{
    margin:0px!important;
}
.mediaTitle{
    position:absolute;
    margin-top: -65px !important;
}
.mediaPattern{
    border-color: #B0B0B0;
    color: #000
}
.mediaPattern::placeholder{
    color: #B0B0B0 !important
}
.blueBtn{
    background-color: #156E8E !important;
    color: #fff !important;
}
.blueBtn,.whiteBtn, .blueBtn:active, .blueBtn:hover, .blueBtn:focus{
    border: 1px solid #156E8E !important;
}
.whiteBtn{
    background-color: #fff !important;
    color: #156E8E !important;
}
</style>